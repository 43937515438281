import React from "react"
import {Link} from "gatsby"

function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row">

            {/*fourth ul*/}
            <ul className="second-list third-list col-md-6 col-lg-3 col-sm-6 footer-widget-container">
              <li>
                <div className="title">
                  <h3 className="text-uppercase">Contact us</h3>
                  <p>
                    294/3, Mathalana, Ruggahawila, Nittambuwa, Sri Lanka
                    <span className="d-block">
                      +94 76 968 0400
                    </span>
                    <span className="text-no-cap">info@fine.lk</span>
                  </p>
                  <a href="tel:+94 0332 280 400" className="footer-hotline">
                    <p className="mb-0 hot-line-footer text-uppercase">Hot line</p>
                    <h4 className="text-light">
                      0332 280 400
                    </h4>
                  </a>
                </div>

              </li>
            </ul>

            {/*second ul*/}
            <ul className="second-list col-md-6 col-lg-3 col-sm-6 footer-widget-container">
              <li>
                <div className="title text-uppercase">
                  <h3>quick links</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/">
                      <span className="fab fa-chevron-right list-icon"></span>
                      <span className="quick-link">Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/products">
                      <span className="fab fa-chevron-right list-icon"></span>
                      <span className="quick-link">Products</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/accessories">
                      <span className="fab fa-chevron-right list-icon"></span>
                      <span className="quick-link">Accessories</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/support">
                      <span className="fab fa-chevron-right list-icon"></span>
                      <span className="quick-link">Support</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <span className="fab fa-chevron-right list-icon"></span>
                      <span className="quick-link">About</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <span className="fab fa-chevron-right list-icon"></span>
                      <span className="quick-link">Contact</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            {/*third ul*/}
            <ul className="first-list col-md-6 col-lg-3 col-sm-6 clearfix">
              <li>
                <div className="title text-uppercase">
                  <h3>About Fine.lk</h3>
                </div>

                <p>
                  Fine Enterprises aspires to make lives of business and technicians easier by introducing state of the
                  art machineries and tools.
                </p>

                <br/>
                <Link to="/about" className="footer-read">Read More</Link>
              </li>
            </ul>

            {/*first ul*/}
            <ul className="first-list col-md-6 col-lg-3 col-sm-6 clearfix">
              <li>
                <div className="title text-uppercase">
                  <h3>Be the first to know</h3>
                </div>

                <p>
                  You can choose the right tools to start your work. We offer
                  thousands of tools and tools related accessories to make your
                  daily work easy
                </p>

                <br/>

                <form className="newsletter">
                  <input className="email" type="email" placeholder="Your email..."/>
                  <button type="submit">
                    <span className="fab fa-send"></span>
                  </button>
                </form>
              </li>
            </ul>

          </div>
        </div>
        <div className="footer-bottom text-light d-flex align-items-center justify-content-between w-100">
          <div
            className="container text-light d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between w-100">
            <p className="m-0 ours">Made with <span className="fab fa-heart"></span> <a href="https://www.scripterlab.com/" className="scripter_lab_link" rel="nofollow" target="_blank">Scripterlab.</a></p>
            <p className="m-0 clients text-center">© {new Date().getFullYear()} <span>Fine Enterprises.</span> All Rights Reserved</p>
          </div>
        </div>
      </footer>


    </>
  )
}

export default Footer