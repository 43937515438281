import React from "react"
import Light from "../images/logo-light.png"
import $ from "jquery"
import Social from "./social"
import { Link } from "gatsby"

function Navigation() {
  // design code start here
  return (
    <header className='position-fixed w-100 ' id='header'>
      {/*header top section start here*/}
      <nav id='topNav' className="nav-bg-light">

      <div className="inner-nav container justify-content-between align-items-center">
        <Link to="/" className="fine-logo">
          <img src={Light} alt="logo of fine.lk"/>
        </Link>

        <ul className="fine-nav">
          <li className="fine-nav-links"><Link to="/" className='text-uppercase'>home</Link></li>
          <li className="fine-nav-links"><Link to="/products" className='text-uppercase'>products</Link></li>
          {/*<li className="fine-nav-links"><Link to="/accessories" className='text-uppercase'>accessories</Link></li>*/}
          <li className="fine-nav-links"><Link to="/support" className='text-uppercase'>support</Link></li>
          <li className="fine-nav-links"><Link to="/about" className='text-uppercase'>about us</Link></li>
          {/*<li className="fine-nav-links"><Link to="/contact" className='text-uppercase'>contact</Link></li>*/}
          <div className="mobile-social">
            <Social></Social>
          </div>

        </ul>

        <div className="large-hotline">
          <a href="tel:+94 0332 280 400" className='ml-1 align-items-center'>
            <div className="d-flex flex-column text-right">
              <span className='contact-info font-weight-bold'>Hot line</span>
              <span className='contact-no font-weight-bold'>0332 280 400</span>
            </div>
            <div className="call-icon">
              <span className="fas fa-phone call"></span>
            </div>
          </a>
        </div>

        <div className="menu-wrapper" onClick={navToggle}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </nav>
  </header>
)
  // nav toggle function start here
  function navToggle (){
    $('nav').toggleClass('nav-toggle');
  }

}

export default Navigation
