import React from "react"

function Social() {
  return(
    <>
      <div className="social-wrapper">
        <div className="fine-social-icon">
          <a href="tel:+94 0332 280 400" className='d-flex flex-row ml-1 align-items-center'>
            <div className="d-flex flex-column text-right">
              <span className='contact-info font-weight-bold'>Hot line</span>
              <span className='contact-no font-weight-bold'>0332 280 400</span>
            </div>
            <div className="call-icon">
              <span className="fas fa-phone call"></span>
            </div>
          </a>
        </div>
        <div className="icons">
          <a href="https://www.facebook.com/FineToolsCompany/" rel="nofollow" target="_blank" className="d-flex align-items-center fb-link">
            <p className="mb-0 mr-1 fb">Facebook</p>
            <span className="fab fa-facebook-f desc-icon"></span>
          </a>
          <a href="https://www.instagram.com/fine.lk/" rel="nofollow" target="_blank" className="d-flex align-items-center fb-link">
            <p className="mb-0 mr-1 fb">Instagram</p>
            <span className="fab fa-instagram desc-icon"></span>
          </a>
          <a href="https://twitter.com/fine_lk?s=08" rel="nofollow" target="_blank" className="d-flex align-items-center fb-link">
            <p className="mb-0 mr-1 fb">Twitter</p>
            <span className="fab fa-twitter desc-icon"></span>
          </a>
          <a href="https://wa.link/xpy65e" target="_blank" rel="nofollow" className="d-flex align-items-center fb-link">
            <p className="mb-0 mr-1 fb">Whatsapp</p>
            <span className="fab fa-whatsapp desc-icon"></span>
          </a>
          <a href="#" rel="nofollow" target="_blank" className="d-flex align-items-center fb-link">
            <p className="mb-0 mr-1 fb">Linkedin</p>
            <span className="fab fa-linkedin desc-icon"></span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Social