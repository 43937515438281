import React from "react"
import Slider from "react-slick"

const arr = [1 , 2 , 3 , 1 , 2 , 3 , 3 , 1 , 2 , 3];

function SliderBack() {

  var settings = {
    autoplay:true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows:false
        }
      },
    ]
  };

  return(
    <>
      <div id="heroSlide" className="hero-slider height-100-mob">
        <div className="hero-slider-content h-100">
          <Slider {...settings}>
            <div className="height-100-mob bg-1"></div>
            <div className="height-100-mob bg-2"></div>
            <div className="height-100-mob bg-3"></div>
            {/*<div className="height-100-mob bg-4"></div>*/}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default SliderBack
