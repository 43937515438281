import React from "react"


const Loading = () =>{
    return (
       <div className="loader_wrapper">
            <div className="loader">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
       </div>
    )
}

export default Loading