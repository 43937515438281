import React from "react"

class MainNav extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass)
    this.toggleBodyClass()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass)
  }

  toggleBodyClass = () => {

    if (window.scrollY < 70) {
      document.body.classList.remove("navActive")
    }
    if (window.scrollY >= 70) {
      document.body.classList.add("navActive")
    }
  }

  render() {
    return (
      <>
      </>
    )
  }
}

export default MainNav