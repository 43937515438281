/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// importing style sheets
import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NProgress from 'nprogress'


import Header from "./header"
import "./layout.css"
import Navigation from "./navigation"
import Footer from "./footer"
import MainNav from "../js/main"
import {useEffect , useState} from 'react'
import Loading from "./Loader"



const Layout = ({ children }) => {

  const [isLoading , setIsloading] = useState(true)

  useEffect(() => {
    setTimeout(()=>{
      setIsloading(false)
    }, 2500)
  })
  



        // design code start here
        return (
        <>
          {
            isLoading==true ? <Loading/> : <><div className='fine-header w-100 h-100'>
            <MainNav/>
            <Navigation/>
          </div>
          <main>{children}</main>
          <div className="fine-footer">
        <Footer/>
      </div></>
          }
    </>
  )

  // set height 100% in mobiles
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
