import PropTypes from "prop-types"
import React from "react"
import SliderBack from "./sliderBack"

class Header extends React.Component {

  render() {
    return (
      <>
        <SliderBack></SliderBack>
        <div className="slider-top">
          <header className='hero-header position-relative height-100-mob overflow-hidden'>

            <div className="hero-quote-wrapper position-absolute d-flex flex-column align-items-center">
              <h1 className='text-light text-center text-uppercase hero_tittle'>
                Powerful and state-of-the-art tools for everyone<span className="d-block h4">
                Quality | Reliability | Efficiency
</span>
              </h1>
              {/*<div className="hero-btn-wrapper d-flex flex-column flex-md-row mt-3">*/}
              {/*  <button type="button" className='btn rounded-pill text-uppercase'>*/}
              {/*    Start Choosing*/}
              {/*  </button>*/}
              {/*  <button type="button" className='btn rounded-pill text-uppercase'>*/}
              {/*    Order Now*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>

            <div className="scroll-bottom">
              <a href="#serviceSection">
                scroll
              </a>
            </div>
          </header>
        </div>
      </>
    )
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
